.queue-button{
    display : grid;
    width : 100%;
    height : 100%;
}

.waiting{
    display : grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 6;
    grid-row-end: 7;
    background: #013220;
    border-color : #f0f9ff;
    border-style : inset;
    height : 75%;
    width : 100%;
    margin: auto;
    align-items: center;
    justify-items: center;
}

.waiting-text{
    display : grid;
    overflow: hidden;
    white-space: nowrap;
}
