.play-wrapper{
    display : grid;
    width : grid;
    height : 100%; 
}


.play-again-wrapper{
    display : grid;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end : 3; 
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    color : ghostwhite;
    width : 100%; 
    height: 100%;
}



.message-display{
    display : grid;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end : 3; 
    grid-template-rows: 1fr 10fr 2fr;
    grid-template-columns: 1fr 10fr 1fr;
    color : red;
    justify-items: center;
    align-items: center;
    width : 100%; 
    height: 100%;
    white-space : pre-wrap;
}

.message-text{
    opacity : 0;
    display: grid;
    background-color: azure;
    color: red;
    width : 100%; 
    height: 100%;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end:3;
    justify-items: center;
    align-items: center;
    border-color: firebrick;
    border-style: inset;
    border-width: thick;
    animation :  new_alert 7s ease-in-out 1;
    white-space : pre-wrap;
}

@keyframes new_alert {
    0%      {border-color: firebrick; background-color: azure; color: red; opacity : 1;}
    5%      {border-color: azure; background-color: firebrick; color : ghostwhite; opacity : 1;}
    10%     {border-color: firebrick; background-color: azure; color: red; opacity : 1;}
    15%     {border-color: azure; background-color: firebrick; color : ghostwhite; opacity : 1;}
    20%     {border-color: firebrick; background-color: azure; color: red; opacity : 1;}
    90%     {border-color: firebrick; background-color: azure; color: red; opacity : 1;}
    95%     {border-color: firebrick; background-color: azure; color: red; opacity : 0.5;}
    100%    {border-color: firebrick; background-color: azure; color: red; opacity : 0;}
  }
