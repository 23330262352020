.rules-page {
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-rows: 0.01fr 10fr 0.5fr 0.5fr 0.5fr;
    background: #161718;
}

.table-of-contents {
    font-family: "Fenix";
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-template-columns: 1fr;
    white-space: nowrap;
}

.toc-container {
    background: darkslategray;
    display: grid;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 5;
    grid-template-columns: 0.5fr 10fr 1fr 0.25fr;
    grid-template-rows: 0.5fr 10fr 2fr;
}

.rules-words {
    color: ghostwhite;
    font-family: "Fenix";
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
    overflow: auto;
}

.vertical-sep {
    background: antiquewhite;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 5;
}

.arrow-bar {
    display: grid;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 5;

}

.picture-sidebar {
    display: grid;
    grid-column-start: 6;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-template-columns: 1fr 10fr 1fr;
    grid-template-rows: 1.5fr 10fr 1.75fr 10fr 1.5fr;
}

.picture-1 {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.picture-2 {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.pic{
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.picture-1:hover {
    cursor: pointer;
}

.picture-2:hover {
    cursor: pointer;
}

.markdown{
    margin-right: 10px;
}

a:link {
    color: #00fdff
}