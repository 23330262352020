.App {
  text-align: center;
  font-family: "Fenix";
}

.div{
  display : grid;
  font-family: "Fenix";
}


#root{
  height : 100%;
  width : 100%;
}

html{
  height : 100%;
  width : 100%;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}

body{
  height :100%;
  width : 100%;
  background-color : black;
  margin : 0px;
}

html, body {
  overflow: hidden;
}

.wrapper {
  height :100%;
  width : 100%;
  display: grid;
}

.grid-container {
  height :100%;
  width : 100%;
  display: grid;
}

.game-name{
  line-height: 100%;
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
  color : white;
  text-align: center;
  font-size: 4rem;
  margin : auto;
  font-family: 'Permanent Marker', cursive;
}

.upper-bar{
  display :grid;
  grid-column: 4/5;
  grid-row: 2/3;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.logo{
  grid-column: 3/4;
  grid-row: 1/4;
  background-size: 100% 100%;
}


@font-face {
  font-family: "LED";   /*Can be any text*/
  src: local("TheLedDisplaySt"),
    url("./Fonts/TheLedDisplaySt.woff2") format("woff2");
}

@font-face {
  font-family: "Fenix";   /*Can be any text*/
  src: local("Fenix-Regular"),
    url("./Fonts/Fenix-Regular.woff2") format("woff2");
}