.captured-piece-popup{
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 11;
    grid-row-end: 12;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    z-index : 30;
    border-color: #f0f9ff;
    border-style: inset;
    border-width: medium;
}

.captured-info{
    grid-row-start: 1;
    grid-row-end: 2;
    z-index : 31;
    font-size: small;
    white-space: pre-wrap;
    text-align: center; 
    margin: auto;
}

.button-row{
    grid-row-start: 1;
    grid-row-end: 3;
    display : grid;
    gap : 0px 5px;
    grid-template-rows : 1fr;
    background-repeat: no-repeat;
    background-position: center;
}