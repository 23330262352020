.piece-button{
    height: 100%;
    width : 100%;
    z-index: 32;
    background-position: center;
    background-size: contain;
}

.piece-button:hover {
    cursor : pointer;
}