.base{
    display : grid;
    height : 100%;
    width : 100%;
}

.nav-bar-container{
    font-family: "Fenix";
    display : grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    background-color: #3D3938;
    color : ghostwhite;
}

.screen{
    color : ghostwhite;
    margin: auto;
    height: 100%;
    Width : 100%;
}