.display-winner{
    display : grid;
    grid-template-columns: 1fr 2.5fr 10fr 0.5fr 2fr 1fr;
    grid-template-rows: 1fr 5fr 8fr 3fr;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-top : 42%;
    margin-bottom : 45%;
    margin-left : 32%;
    margin-right : 32%;
    background-color: black;
    color : ghostwhite;
    border-color: indianred;
    border-style: ridge;
    z-index: 10;
    font-size: large;
    border-radius: 1em;
    text-align: center;
}

.winning-message{
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 5;
    text-align: center;
    margin:auto;
    font-family: 'Poor Story', cursive;
    font-size: larger;
}


.close-button{
    height: 100%;
    width :100%;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 3;
    text-align: center;
    margin:auto;
    z-index: 12;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.close-button:hover{
    cursor:pointer;
    height: 90%;
    width: 90%
}