.forward-back-arrows{
    display : grid;
    height : 100%;
    width : 100%;
    grid-template-columns: .1fr 4fr 8fr 4fr .1fr;
    grid-template-rows: 1fr;
}

.forward-button{
    display: grid;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
    background-size:     contain;              
    background-position: right center;
    z-index: 720;
}


.gray-forward-button{
    display: grid;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
    background-size:     contain;              
    background-repeat:   no-repeat;
    background-position: right center;
    z-index: 720;
    display : none;
}

.back-button{
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    background-size: contain;          
    background-position: right center;  
    height: 100%;
    width: 100%;
    z-index : 720; 
    -webkit-transform:rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); 
}


.gray-back-button{
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    background-size:     contain;              
    background-repeat:   no-repeat;
    background-position: right center;  
    z-index : 720; 
    -webkit-transform:rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); 
    display : none
}

.page-number{
    display: grid;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    color : whitesmoke;
    align-self : center;
    justify-self: center;
    font-family: "Fenix";
    font-size: xx-large;

}


.back-button:hover{
    cursor : pointer;  
}

.forward-button:hover{
    cursor :pointer;
}

.gray-back-button:hover{
    cursor :not-allowed;  
}

.gray-forward-button:hover{
    cursor : not-allowed;
}

.new-game-button:hover{
    cursor :pointer;
    background-color:darkgreen;
}

.message{
    display: grid;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    width : 100%;
    font-size: 80%;
    text-align: center;
    color : ghostwhite;
    margin :auto;
}

