.board-and-sideboard{
    display : grid;
    grid-row : 3/4;
    grid-column : 2/5;
    grid-template-rows: 1fr; 
    grid-template-columns : 8fr .25fr 3fr;
}

.chessboard { 
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    height: 100%;
    width : 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);
    overflow : -moz-hidden-unscrollable;
    margin: 0px 0px;
    z-index: 1;
  }

.queue-sideboard-wrap{
        display : grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 2;
        background-color:  #3D3938;
        border-color:antiquewhite;
        color : ghostwhite;
        border-style: inset;
        opacity : .9;    
}