.wordshow{
    display: grid;
    white-space: nowrap;
    font-family: "LED";
    background: linear-gradient(#00fefc, #cdfdfe, #00fefc, #cdfdfe, #00fefc, #cdfdfe, #00fefc);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    height: '100%';
    width: '100%';
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    align-items: center;
    justify-items: center;
}