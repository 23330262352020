.asset-loader{
    color: ghostwhite;
    display: grid !important; 
    align-items: center;
    justify-items: center;
    grid-column-start: 1;
    grid-column-end: -1;
    grid-row-start: 1;
    grid-row-end: -1;
    height: 100% ;
    width : 100% ; 
}


