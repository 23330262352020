.queue-sideboard{
    display : grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-template-rows: 1fr 1fr 0.5fr 4.5fr 1.5fr 1fr 0.5fr;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    background-color:  #3D3938;
    border-color:antiquewhite;
    color : ghostwhite;
    border-style: inset;
    opacity : .9;
}

.game-type{
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    width : 100%;
    align-items: flex-end;
}

.time-control{
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    width : 100%;
    align-items: flex-end;
}

.selector{
    display : flex;
    justify-content: baseline;
}

.submit{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 6;
    grid-row-end: 7;
}

.toggle-box{
    display : none;
} 

/* .toggle-box{
    background : black;
    border-color: antiquewhite;
    border-style: groove;
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
    width : 100%;
    grid-template-columns: 1fr 12fr 1fr;
    grid-template-rows: 0.25fr 1fr 1fr 1fr 1fr 0.25fr;
} */

.en-passant-toggle{
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    width : 100%;
}

.castling-toggle{
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    width : 100%;
}

.decoherence-toggle{
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
    width : 100%;
}

.random-capture-toggle{
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 6;
    width : 100%;
}

