.bounding-box{
    display : grid; 
    height : 100%; 
    width : 100%;
}

.button-box{
    display : grid; 
    height : 85%; 
    width : 85%;
}

.inner-button{
    font-family: "Fenix";
    display : grid;
    text-align: center;
}

.button-box:hover{
    cursor: pointer;
}
.inner-button:hover{
    cursor : pointer;
}