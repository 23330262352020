.landing-page {
    display: grid;
    height: '100%';
    width: '100%';
    grid-template-rows: 3fr 6fr 3.5fr 0.5fr;
    grid-template-columns: 2fr 10fr 2fr;
    grid-column-start: 1;
    grid-column-end: 12;
    grid-row-start: 1;
    grid-row-end: 12;
}

.banner {
    display: grid;
    height: '100%';
    width: '100%';
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.motto {
    display: grid;
    height: '100%';
    width: '100%';
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    color: ghostwhite;
    white-space: pre-wrap;
}

.bottom-slides{
    display: grid;
    height: '100%';
    width: '100%';
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
}

.multiverse{
    font-family: "LED";
    color: #fe019a;
    background: linear-gradient(#7928CA, #FF0080, #7928CA, #FF0080, #7928CA, #FF0080, #7928CA, #FF0080);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: grid;
    height: '100%';
    width: '100%';
    grid-row-start: 1;
    grid-row-end: 2;
    align-items: center;
    justify-items: center;
    white-space: nowrap;
}


.f2p{
    font-family: "LED";
    color: #fe019a;
    background: linear-gradient(#7928CA, #FF0080, #7928CA, #FF0080, #7928CA, #FF0080, #7928CA, #FF0080);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: grid;
    height: '100%';
    width: '100%';
    grid-row-start: 3;
    grid-row-end: 4;
    align-items: center;
    justify-items: center;
    white-space: nowrap;
}



* {
    box-sizing: border-box;
}



/* 
@keyframes ticker {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 0;
    }

    1% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    100% {
        transform: translate3d(-200%, 0, 0);
        opacity: 1;
    }
}

.ticker-wrap {
    display: inline-block;
    height: 100%;
    overflow: hidden;
    background-color: rgba(#000, 0.9);
    padding-left: 100%;
    box-sizing: content-box;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
}

.ticker1 {
    opacity: 0;
    display: inline-block;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ticker;
    animation-duration: 20s;
    animation-delay: 0s;
    align-self: center;
    grid-row-start: 1;
    grid-row-end: 2;
}

.ticker2 {
    opacity: 0;
    display: inline-block;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ticker;
    animation-duration: 20s;
    animation-delay: 6s;
    align-self: center;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
}

.ticker3 {
    opacity: 0;
    display: inline-block;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ticker;
    animation-duration: 20s;
    animation-delay: 3s;
    align-self: center;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
}


.ticker1 .item {

    font-family: "LED";
    display: inline-block;
    padding: 0 0rem;
    color: #fe019a;
    background: linear-gradient(#7928CA, #FF0080);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

}

.ticker2 .item {
    font-family: "LED";
    display: inline-block;
    padding: 0 6rem;
    background: linear-gradient(#00fefc, #cdfdfe, #00fefc);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;


}

.ticker3 .item {
    font-family: "LED";
    display: inline-block;
    padding: 0 6rem;
    color: #fe019a;
    background: linear-gradient(#7928CA, #FF0080);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
} */
