.button-bar{
    display : grid;
    grid-template-rows: 34fr 1fr;
    background-color: #3D3938;
    color : ghostwhite;
}

.left-button-area{
    display: grid;
    grid-column: 4/5;
    grid-row: 1/2;
}

.right-button-area{
    display: grid;
    grid-column: 6/7;
    grid-row: 1/2;
}

.left-nav-bar-buttons{

    display : grid;
    margin : auto;
    width: 100%;
    height: 100%;
    white-space: nowrap;

}

.right-nav-bar-buttons{
    display : grid;
    margin : auto;
    width: 100%;
    height: 100%;
}


.play-button-in-navbar{
    display : grid;
    margin : auto;
    grid-column: 2/3;
    width: 100%;
    height: 100%;
}


.wrinkle-brain-logo{
    display : grid;
    grid-column: 1/2;
    grid-row: 1/2;
    background-size : contain;
    background-repeat: no-repeat;
    background-position: left;
}

.nav-bar-separator{
    display : grid;
    grid-column: 1/7;
    grid-row: 2/3;
    background-color: antiquewhite;
}