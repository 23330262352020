.picture-pop-up-wrapper{
    display: grid;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: -1;
    align-self: center;
    justify-self: center;
    align-items: center;
    justify-items: center;
    z-index: 300;
}


.picture-pop-up-frame{
    display:grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-template-rows: 0.05fr 0.2fr 0.3fr 10fr 0.3fr 0.2fr 0.05fr;
    grid-template-columns: 0.05fr 0.2fr 0.3fr 10fr 0.3fr 0.2fr 0.05fr;
    width: 100%;
    height: 100%;
    z-index: 301;
    background-image: linear-gradient(rgba(229,228,226,1), rgba(229,228,226,.3));
}

.picture-pop-up{
    display:grid;
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 6;
    width: 100%;
    height: 100%;
    background-color:  #161718;
    background-size : contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 302;
}


.popup-close-button{
    display:grid;
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 4;
    background-size : contain;
    background-position: center;
    width: 100%;
    height: 100%;
    align-self: center;
    justify-self: center;
    z-index: 303;
}

.popup-close-button:hover{
    cursor: pointer;
    width: 85%;
    height: 85%;
}