@keyframes checking {
    0%   {border-color: red; border-width: medium; border-style:solid; z-index: 100;}
    25%  {border-color: red; border-width: medium; border-style:solid; z-index: 100; }
    50%  {border-color: red; border-width: medium; border-style:none; z-index: 100; }
    100% {border-color: red; border-width: medium; border-style:none; z-index: 100;}
  }

/* .opponent-piece{
    -webkit-transform:rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); 
} */

.tile{
    width: 100%;
    height: 100%;
    display : grid;
    grid-template-rows: 1fr; 
    grid-template-columns : 1fr;
    place-content: center;
}

.black-tile{
    background-color :darkolivegreen;
}

.black-tile-alt{
    background-color : #a8b235;
}

.white-tile{
    background-color: burlywood;
}

.white-tile-alt{
    background-color: #e7d473;
}

.tile .chess-piece {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 95%;
}

.tile .chess-piece:hover{
    cursor : grab;

}

.tile .chess-piece:active{
    cursor : grabbing;
}

.tile img {
    width : 80%;
    height : auto;
}
