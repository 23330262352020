.sideboard {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-template-rows: 0.25fr 2.75fr 0.3fr 1.5fr 0.3fr 0.1fr 2fr 1.6fr 2fr 0.1fr 0.3fr 1.5fr 0.3fr 2.75fr 0.25fr;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    background-color: #3D3938;
    border-color: antiquewhite;
    color: ghostwhite;
    border-style: inset;
    opacity: .9;
}

.white-captured {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-template-columns: 1fr 5fr 5fr 5fr 5fr 5fr 1fr;
    grid-template-rows: 1fr 5fr 5fr 5fr 1fr;
    place-content: center;
    z-index: 20;
    background-color: antiquewhite;
    border-style: solid;
    border-color: black;
    border-width: medium;
    border-width: thick;
}


.black-captured {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-template-columns: 1fr 5fr 5fr 5fr 5fr 5fr 1fr;
    grid-template-rows: 1fr 5fr 5fr 5fr 1fr;
    place-content: center;
    z-index: 20;
    background-color: antiquewhite;
    border-style: solid;
    border-color: black;
    border-width: thick;
}

.white-clock {
    display: grid;
    background: black;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 9;
    grid-row-end: 10;
    align-items: center;
    grid-template-columns: 6fr 5fr 1fr;
    grid-template-rows: 0.05fr 1fr;
    justify-items: right;
}

.black-clock {
    display: grid;
    grid-template-columns: 1fr 5fr 6fr;
    grid-template-rows: 1fr .05fr;
    background: black;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 7;
    grid-row-end: 8;
    align-items: center;
}

.black-clock-text {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
}

.white-clock-text {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}


.arrows {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    background: black;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 8;
    grid-row-end: 9;
    align-items: center;
}


.text {
    display: grid;
    margin: auto;
    color: white;
}

.upper-divider {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 6;
    grid-row-end: 7;
    background: antiquewhite;
    border-radius: 50%;
    border-color: transparent;
    border-width: thin;
    border-style: solid;


}

.lower-divider {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 10;
    grid-row-end: 11;
    background: antiquewhite;
    border-radius: 50%;
    border-color: transparent;
    border-width: thin;
    border-style: solid;

}


.white-clock-top{
    display: grid;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    height: 100%;
    justify-self: left;
    background: linear-gradient(90deg,
        transparent 50%,
        rgba(0, 255, 255, 0.5),
        rgb(0, 255, 255)
    );
    animation: animateBottom 2s ease-in-out infinite;
}

.black-clock-bottom {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    height: 100%;
    justify-self: right;
    background: linear-gradient(90deg,
        rgb(0, 255, 255),
        rgba(0, 255, 255, 0.5),
        transparent 70%
    );
    animation: animateBottom 2s ease-in-out infinite;
}

@keyframes animateBottom {

    0%,
    35% {
        opacity: 0;
        width: 0%;
    }

    85% {
        opacity: 1;
        width: 100%;
    }

    86%,
    100% {
        opacity: 0;
    }
}